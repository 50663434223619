/* Default color definitions... */
class Color {
    constructor(name,r,g,b,a=1){
        this.name = name;
        this.rgb = [r,g,b];
        this.transp = a;
        return this;
    }

    getHex(){
        return "#" + ((1 << 24) + (this.rgb[0] << 16) + (this.rgb[1] << 8) + this.rgb[2]).toString(16).slice(1);
    }

    getRgb(){
        return this.rgb;
    }

    getRgba(){
        return 'rgba('+this.rgb+','+this.transp+')';
    }
}

const col = {
    base: new Color('Basic',0,0,0),
    bg: new Color('Basic_BG',255,255,255),
    forces: new Color('Forces',255,0,0),
    c_vis: new Color('Concrete_Visualisation',200,200,200),
    c: new Color('Concrete_QS',0,200,0),
    s: new Color('Steel',0,0,200),
    sk: new Color('Schnittkraefte_Linie',255,165,0),
    sk_bg: new Color('Schnittkraefte_BG',255,233,199),
    eps: new Color('Dehnungsebene',241,146,60),
    def: new Color('Deformations',0,0,150),
    sf: new Color('Stress_Field',0,150,0),
    sf2: new Color('Stress_Field_Secondary',0,230,0),
    sf_bg: new Color('Stress_Field_BG',200,255,200,0.7),
    fwm_t: new Color('FWM_Tension', 25,100,200),
    fwm_c: new Color('FWM_Compression', 70,120,25),
};

class Point {

    constructor(x, y) {
        this.x = x;
        this.y = y;
        this.zIndex = 100;
    }

    clone() {
        return new Point(this.x, this.y);
    }

    move(x,y){
        this.x = this.x + x;
        this.y = this.y + y;

        return this;
    }

    rotate(ang, rotX = 0, rotY = 0) {

        let tmp_x = this.x;
        let tmp_y = this.y;

        this.x = Math.round( ( ((tmp_x - rotX)*Math.cos(ang * (Math.PI / 180))) - ((tmp_y - rotY)*Math.sin(ang * (Math.PI / 180))) + rotX) * 100000) / 100000;
        this.y = Math.round( ( ((tmp_y - rotY)*Math.cos(ang * (Math.PI / 180))) + ((tmp_x - rotX)*Math.sin(ang * (Math.PI / 180))) + rotY) * 100000) / 100000;
        return this;
    }

    mirrorX(){
        this.x = -this.x;
    }

    mirrorY(){
        this.y = -this.y;
    }

    //TODO: genPlotData, setters
}

class Line {

    /**
     *
     * @param args Can be an Array of at least two Point Instances (e.g.: [new Point(1,1), new Point(2,2)]) or a 2D Array ([[x1, y1], [x2, y2]]) or two Arrays [x1,x2],[y1,y2]
     */
    constructor(...args) {

        this.pts = [];
        this.color = [0,0,0];
        this.width = 1;
        this.dash = 'solid';
        this.name = '';
        this.mode = 'lines';
        this.fill = 'none';
        this.fillcolor = '';
        this.shape = 'linear';
        this.smoothing = 1;
        this.zIndex = 100;
        this.text = '';
        this.textpos = 'middle center';

        if (args.length === 1 && args[0] instanceof Point) {
            this.pts = [new Point(0,0), args[0]];
        } else if (args.length === 1 && args[0][0] instanceof Array && args[0][0].length === 2 && isNaN(args[0][0][0]) ) {
            this.pts = args[0].map(function (x) {
                return new Point(x[0], x[1]);
            });
        }  else if (args.length === 2 && args[0] instanceof Array && args[0].length === args[1].length && isNaN(args[0][0][0])) {
            this.pts = args[0].map(function (x,i) {
                return new Point(x, args[1][i]);
            });
        } else if (args[0] instanceof Array && args[0][0] instanceof Point && args[0].length > 1){
            this.pts = args[0];
        } else {
            console.log(args);
            console.log("No valid input arguments for class line!");
        }
    }

    move(x,y){
        this.pts.forEach(function(elm){
            return elm.move(x,y);
        });
        return this;
    }

    /**
     * Method to rotate a line around a rotation center (rotX, rotY)
     * @param ang       Angle in degrees
     * @param rotX      X-Coordinate of rotation center
     * @param rotY      Y-Coordinate of rotation center
     * @returns {Line}
     */
    rotate(ang = 90, rotX = 0, rotY = 0) {
        this.pts.forEach(function (elm) {
            return elm.rotate(ang, rotX, rotY);
        });
        return this;
    }

    mirrorX(){
        this.pts.forEach(function(elm){
            return elm.mirrorX();
        });
        return this;
    }

    mirrorY(){
        this.pts.forEach(function(elm){
            return elm.mirrorY();
        });
        return this;
    }

    setColor(color){

        if(Array.isArray(color) && color.length === 3){
            this.color = [color[0],color[1],color[2]];
        }else{
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
            this.color = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
        }

        return this;
    }

    setWidth(width = 1){
        this.width = width;
        return this;
    }

    setDash(dash = 'solid'){
        this.dash = dash;
        return this;
    }

    setName(name = ''){
        this.name = name;
        return this;
    }

    setMode(mode = 'lines'){
        this.mode = mode;
        return this;
    }

    setFill(color = col.base.getRgba(), mode = 'toself'){
        this.fillcolor = color;
        this.fill = mode;
        return this;
    }

    setShape(shape = 'linear', smoothing = 1){
        this.shape = shape;
        this.smoothing = smoothing;
        return this;
    }

    setZIndex(zind = 100){
        this.zIndex = zind;
        return this;
    }

    /**
     * Method to create plot data for plot.ly js (Struct for newplot or restyle function)
     */
    getPlotData(){
        let xVal = [], yVal = [];

        this.pts.forEach(function (item) {
            xVal.push(item.x);
            yVal.push(item.y);
        });

        return new pltData({
            x: xVal,
            y: yVal,
            name: this.name,
            mode: this.mode,
            fill: this.fill,
            fillcolor: this.fillcolor,
            line: {
                width: this.width,
                color: 'rgb(' + this.color + ')',
                dash: this.dash,
                shape: this.shape,
                smoothing: this.smoothing,
            },
            text: this.text,
            textposition: this.textpos
        }, this.zIndex);
    }
}

class Text extends Line{
    constructor(x = 0, y = 0, txt = 'Blubb') {
        super(new Point(x,y));

        this.setMode('text');
        this.text = ['', txt];
    }

    setPosition(pos = 'middle center'){
        this.textpos = pos;
    }

    rotate(ang = 90, rotX = 0, rotY = 0) {
        super.rotate(ang, rotX, rotY);

        console.log(ang);

        if( ang % 360 !== 0) {
            if ((ang % 360) <= 0) {
                this.setPosition('middle right');
            } else {
                this.setPosition('middle left');
            }
        }
    }
}

class Circle extends Line{
    constructor(x = 0, y = 0, r = 1, opnAng = 360){
        let n = 100;
        let xVal = [];
        let yVal = [];
        let ang = 0;

        for(let i = 0; i<n+1; i++){
            ang = (opnAng/360)*Math.PI*2*i/n;
            xVal.push(r*Math.cos(ang));
            yVal.push(r*Math.sin(ang));
        }

        super(xVal,yVal);

        this.x = x;
        this.y = y;
        this.r = r;
        this.setShape('spline',1.3);
        this.move(x,y);
    }
}

class pltData{

    constructor(data = [], zind = [], layout = {}){
        if(Array.isArray(data) && Array.isArray(zind) && data.length === zind.length) {
            this.data = data;
            this.zIndex = zind;
        }else{
            this.data = [data];
            this.zIndex = [zind];
        }

        if(Object.keys(layout).length === 0 && layout.constructor === Object){
            //Default Layout:
            this.layout = {
                hovermode: false,
                height: 350,
                margin: {
                    l: 60,
                    r: 0,
                    b: 40,
                    t: 30,
                    pad: 0
                },
                showlegend: false,

            };
        }else{
            this.layout = layout;
        }

        //Third parameter for plotly...
        this.options = {responsive: true, displaylogo: false};
    }

    concenate(data){
        if(data instanceof pltData){

            for(let i=0; i < data.data.length; i++){
                //Find position (index) in zIndex array...
                let ind = this.zIndex.findIndex(function(num){
                    return num > data.zIndex[i];
                });

                if(ind === -1){
                    // Push
                    this.data.push(data.data[i]);
                    this.zIndex.push(data.zIndex[i]);
                }else{
                    this.data.splice(ind,0,data.data[i]);
                    this.zIndex.splice(ind,0,data.zIndex[i]);
                }
            }
            //this.data = this.data.concat(data.data);
            this.layout = Object.assign(this.layout, data.layout);
        }
    }

    addData(data = []){
        if(Array.isArray(data)){
            this.data = this.data.concat(data);
        }else{
            this.data = this.data.push(data);
        }
    }

    addLayout(data = {}){
        this.layout = Object.assign(this.layout, data);
    }
}

class Group{

    constructor(elm = [], zind = 100){
        if(Array.isArray(elm)){
            this.elm = elm;
        }else{
            this.elm = [elm];
        }

        this.zIndex = zind;

    }

    addElm(elm){
        if(Array.isArray(elm)){
            this.elm.concat(elm);
        }else{
            this.elm.push(elm);
        }
        return this;
    }

    move(x = 0, y = 0){
        this.elm.forEach(function(elm){
            elm.move(x,y);
        });
        return this;
    }

    rotate(ang = 90, rotX = 0, rotY = 0){
        this.elm.forEach(function(elm){
            elm.rotate(ang, rotX, rotY);
        });
        return this;
    }

    mirrorX(){
        this.elm.forEach(function(elm){
            return elm.mirrorX();
        });
        return this;
    }

    mirrorY(){
        this.elm.forEach(function(elm){
            return elm.mirrorY();
        });
        return this;
    }

    setColor(color = col.base.getHex()){
        this.elm.forEach(function(elm){
            if(typeof elm.setColor === 'function'){
                elm.setColor(color);
            }
        });
        return this;
    }

    setFill(color = col.base.getHex(), mode = 'toself'){
        this.elm.forEach(function(elm){
            if(typeof elm.setFill === 'function'){
                elm.setFill(color, mode);
            }
        });
        return this;
    }

    setZIndex(zind = 100){
        this.zIndex = zind;
        return this;
    }

    getPlotData(){

        let plt = new pltData();

        this.elm.forEach(function(elm){
            if(elm.getPlotData() instanceof pltData){
                plt.concenate(elm.getPlotData());

            }else{
                plt = elm.getPlotData();
            }
        });

        //Set all zIndices to zIndex of group...
        plt.zIndex = new Array(plt.zIndex.length).fill(this.zIndex);

        return plt;
    }



}

class Rectangle extends Group{


    constructor(width = 100, height = 50, x = 0, y = 0){
        super();
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
        this.addElm(new Line([x-width/2, x+width/2, x+width/2, x-width/2, x-width/2], [y-height/2, y-height/2, y+height/2, y+height/2, y-height/2]));
    }


}

class Arrow extends Group{

    constructor(x = 0, y = 0, length = 1, ang = 0, sc = 1){
        super();

        this.x = x;
        this.y = y;
        this.length = length;
        this.scale = sc;
        this.angle = ang;

        let arrow = new Line([x, x, x+0.1*sc, x, x-0.1*sc, x],[y+length, y+0.4*sc, y+0.4*sc, y, y+0.4*sc, y+0.4*sc]);
        this.addElm(arrow);
        this.rotate(this.angle);
    }
}

class MomentArrow extends Group{

    constructor(x = 0, y = 0, length = 1.1, ang = 0, sc = 0.8, colorIt = 1){
        super();
        this.x = x;
        this.y = y;
        this.length = length;
        this.scale = sc;
        this.angle = ang;

        let arrow = new Line([x, x, x+0.1*sc, x, x+0.1*sc, x, x-0.1*sc, x, x-0.1*sc, x],[y+length, y+0.8*sc, y+0.8*sc, y+0.4*sc, y+0.4*sc, y, y+0.4*sc, y+0.4*sc, y+0.8*sc, y+0.8*sc]);
        this.addElm(arrow);
        this.rotate(this.angle);
        if(colorIt === 1){
            this.setColor(col.forces.getHex());
            this.setFill(col.forces.getHex());
        }
    }
}

class MomentArrowCircular extends Group{
    constructor(x = 0, y = 0, openAng = 140, inverted = false, sc=0.8, r=0.5, colorIt = 1, rotated = 1){
        super();

        this.x = x;
        this.y = y;
        this.openAng = openAng;
        this.scale = sc;
        this.r = r;

        let arrowAng = 0.4*sc*360/(2*r*Math.PI);

        let n = 100;
        let xVal = [];
        let yVal = [];
        let ang = 0;

        for(let i = 0; i<n+1; i++){
            ang = (arrowAng/360)*Math.PI*2*i/n;
            xVal.push(r*sc*Math.cos(ang));
            yVal.push(r*sc*Math.sin(ang));
        }

        xVal.push((r+0.2)*sc,r*sc);
        yVal.push(0,0);

        this.arrow = new Line(xVal,yVal);

        this.addElm(this.arrow);
        this.addElm(new Circle(0,0,sc*r,-this.openAng+arrowAng));

        if(colorIt === 1){
            this.setColor(col.forces.getHex());
            this.setFill(col.forces);
        }

        if(rotated){
            this.rotate(arrowAng);
        }else{
            this.rotate(-arrowAng);
        }

        if(inverted) {
            this.mirrorY();
        }

        this.move(x,y);
    }

    setFill(color = col.forces){
        this.arrow.setFill(color.getHex());
    }

}

class Force extends Arrow{

    constructor(x = 0, y = 0, length = 1, ang = 0, sc = 1){
        super(x,y,length,ang,sc);

        this.setColor(col.forces.getHex());
        this.setFill(col.forces.getHex());

        return this;
    }
}

class DistForce extends Group{

    constructor(x1 = 0, y1 = 0, x2 = 1, y2 = 0, height = 1, sc = 1, num = 0){
        super();

        this.pts = [new Point(x1,y1), new Point(x2,y2)];

        //Calc length...
        this.length = Math.sqrt(Math.pow(x2-x1,2)+Math.pow(y2-y1,2));
        this.angle = Math.atan2(y2-y1, x2-x1);
        this.num = num;  // changed from 0 to num by lg

        //Calculate number of force arrows...
        if(this.num === 0) {
            this.num = Math.round(this.length / ((0.2 + 1) * sc));
            if(this.num < 1){
                this.num = 1;
            }
        }

        //Draw arrows
        //TODO The drawing of the arrows needs to be improved. When a scale is given they to not end at the line of the ditributed force
        for(let i=0; i <= this.num; i++){
            this.addElm(new Force(i*this.length/this.num,0,height,0,sc));
        }
        //Draw line
        this.addElm(new Line([0,this.length],[height, height]).setColor(col.forces.getHex()));

        //Rotate and move everything...
        this.rotate(this.angle*(180/Math.PI)).move(x1, y1);
    }
}

class Support extends Group{

    constructor(x = 0, y = 0, type = 1, sc = 1){
        super();

        this.x = x;
        this.y = y;
        this.type = type;
        this.scale = sc;

        //Triangle
        if (type <=2) {
            this.addElm(new Line([x, x + 0.5 * sc, x - 0.5 * sc, x], [y, y - 0.8 * sc, y - 0.8 * sc, y]));
        }

        if(type === 1){ //Fixed support (three lines)
            this.addElm(new Line([x-0.8*sc, x+0.8*sc],[y-0.8*sc, y-0.8*sc]));
            this.addElm(new Line([x-0.6*sc, x-0.8*sc], [y-0.8*sc, y-sc]));
            this.addElm(new Line([x-0.3*sc, x-0.5*sc], [y-0.8*sc, y-sc]));
            this.addElm(new Line([x, x-0.2*sc], [y-0.8*sc, y-sc]));
            this.addElm(new Line([x+0.3*sc, x+0.1*sc], [y-0.8*sc, y-sc]));
            this.addElm(new Line([x+0.6*sc, x+0.4*sc], [y-0.8*sc, y-sc]));
        }else if(type === 2) { //Sliding support (line below)
            this.addElm(new Line([x - 0.8 * sc, x + 0.8 * sc], [y - 0.8 * sc, y - 0.8 * sc]));
            this.addElm(new Line([x - 0.8 * sc, x + 0.8 * sc], [y - sc, y - sc]));
        }
        else if(type === 3) { //Clamped support (line below)
            this.addElm(new Line([x-0.8*sc, x+0.8*sc],[y, y]));
            this.addElm(new Line([x-0.6*sc, x-0.8*sc], [y, y-0.2*sc]));
            this.addElm(new Line([x-0.3*sc, x-0.5*sc], [y, y-0.2*sc]));
            this.addElm(new Line([x, x-0.2*sc], [y, y-0.2*sc]));
            this.addElm(new Line([x+0.3*sc, x+0.1*sc], [y, y-0.2*sc]));
            this.addElm(new Line([x+0.6*sc, x+0.4*sc], [y, y-0.2*sc]));
        }
    }

}


class Fan extends Group {

    constructor(...args){
        super();

        this.addLines = 2; //Default value

        if((args.length === 1) || (args.length === 2 && !isNaN(args[1]))){          // Input: ([[x,y],[x,y],[x,y]](,add_lines))
            this.pts = args[0].map(function(elm){
                return new Point(elm[0], elm[1]);
            });

            if(args.length === 2){
                this.addLines = args[1];
            }
        }else if(args.length <= 3){    // Input: ([x...],[y...](,add_lines))
            this.pts = args[0].map(function (x,i) {
                return new Point(x, args[1][i]);
            });

            if(args.length === 3){
                this.addLines = args[2];
            }
        }else if(args.length >= 4){      // Input: (x,y,height,alpha(,add_lines))
            this.pts = [new Point(args[0],args[1]), new Point(args[0],args[1]+args[2]), new Point(args[0]+args[2]/Math.tan(args[3]*(Math.PI/180)),args[1]+args[2])];

            if(args.length === 5){
                this.addLines = args[4];
            }
        }else{
            console.log("Error: Invalid input arguments for Fan");
        }

        //Draw Background
        this.addElm(new Line(this.pts).setMode('none').setFill(col.sf_bg.getRgba()).setZIndex(90));

        //Draw sidelines...
        this.addElm(new Line([this.pts[0], this.pts[2]]).setColor(col.sf.getHex()));
        this.addElm(new Line([this.pts[0], this.pts[1]]).setColor(col.sf.getHex()));

        let tmpDistX = (this.pts[2].x-this.pts[1].x)/(this.addLines+1);
        let tmpDistY = (this.pts[2].y-this.pts[1].y)/(this.addLines+1);

        for(let i=1; i<=this.addLines; i++){
            this.addElm(new Line([this.pts[0], new Point(this.pts[1].x+tmpDistX*i, this.pts[1].y+tmpDistY*i)]).setColor(col.sf2.getHex()));
        }
    }

}

class ParallelField extends Group {

    constructor(...args){
        super();

        this.addLines = 3; //Default value

        if((args.length === 1) || (args.length === 2 && !isNaN(args[1]))){          // Input: ([[x,y],[x,y],[x,y],[x,y]](,add_lines))
            this.pts = args[0].map(function(elm){
                return new Point(elm[0], elm[1]);
            });

            if(args.length === 2){
                this.addLines = args[1];
            }
        }else if(args.length <= 3){    // Input: ([x...],[y...](,add_lines))
            this.pts = args[0].map(function (x,i) {
                return new Point(x, args[1][i]);
            });

            if(args.length === 3){
                this.addLines = args[2];
            }
        }else if(args.length >= 4){      // Input: (x,y,height,alpha(,add_lines))
            this.pts = [new Point(args[0],args[1]), new Point(args[0]+args[2]/Math.tan(args[3]*(Math.PI/180)),args[1]+args[2]), new Point(args[0]+2*args[2]/Math.tan(args[3]*(Math.PI/180)),args[1]+args[2]), new Point(args[0]+args[2]/Math.tan(args[3]*(Math.PI/180)),args[1])];

            if(args.length === 5){
                this.addLines = args[4];
            }
        }else{
            console.log("Error: Invalid input arguments for ParallelField");
        }

        //Draw Background
        this.addElm(new Line(this.pts).setMode('none').setFill(col.sf_bg.getRgba()).setZIndex(90));

        //Draw sidelines...
        this.addElm(new Line([this.pts[0], this.pts[1]]).setColor(col.sf.getHex()));
        this.addElm(new Line([this.pts[2], this.pts[3]]).setColor(col.sf.getHex()));

        let tmpDistX_1 = (this.pts[3].x-this.pts[0].x)/(this.addLines+1);
        let tmpDistY_1 = (this.pts[3].y-this.pts[0].y)/(this.addLines+1);
        let tmpDistX_2 = (this.pts[2].x-this.pts[1].x)/(this.addLines+1);
        let tmpDistY_2 = (this.pts[2].y-this.pts[1].y)/(this.addLines+1);

        for(let i=1; i<=this.addLines; i++){
            this.addElm(new Line([new Point(this.pts[0].x+tmpDistX_1*i, this.pts[0].y+tmpDistY_1*i), new Point(this.pts[1].x+tmpDistX_2*i, this.pts[1].y+tmpDistY_2*i)]).setColor(col.sf2.getHex()));
        }
    }
}

class DimLine extends Group{
    constructor(pt1=[0,0], pt2=[1,0], txt='calcLength', r=0.02, lineOffset=0.5, txtOffset = 0.2){
        super();

        let tmpVx = pt2[0]-pt1[0];
        let tmpVy = pt2[1]-pt1[1];
        let length = Math.sqrt(Math.pow(tmpVx,2)+Math.pow(tmpVy,2));
        let ang = Math.atan2(tmpVy,tmpVx)*180/Math.PI;

        this.addElm(new Line([-3*r, length+3*r],[lineOffset, lineOffset])); // Line 1 -> Parallel
        this.addElm(new Line([0,0],[0.3*lineOffset, lineOffset+3*r])); // Line 2 -> Perpendicular
        this.addElm(new Line([length, length],[0.3*lineOffset, lineOffset+3*r])); // Line 3 -> Perpendicular

        this.addElm(new Circle(0,lineOffset,r).setFill(col.base.getHex()));
        this.addElm(new Circle(length,lineOffset,r).setFill(col.base.getHex()));

        if(txt.startsWith('calcLength')){
            txt = Math.round(length*10)/10 + txt.substr(10);
        }

        this.addElm(new Text(length/2,lineOffset+txtOffset,txt));

        this.rotate(ang);
        this.move(pt1[0],pt1[1]);
    }
}